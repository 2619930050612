<template>
  <div class="main_v8">
    <div class="alarmarea">
      <div class="alarmarea_header">
        <h3>Alarm Configuration Summary</h3><span @click="NewAlarm"></span>
      </div>
      <div class="profiletilelist">
        <div class="profiletile" v-if="profile" :style="addNotification ? { zIndex: '1' } : null">
          <div class="profiletile_top">
            <h3 v-if="!detailNewMode">Edit Alarm</h3>
            <h3 v-if="detailNewMode">New Alarm</h3>
            <span @click="profile = !profile"></span>
          </div>
          <p v-if="currentProfile != ''">{{ currentProfile }}</p>
          <div class="profilebuttons">
            <div v-if="!detailNewMode" @click="NewAlarm">New</div>
            <div @click="SaveAlarm(selectedRow)">Save</div>
            <div v-if="!detailNewMode" @click="SaveAs">Save As</div>
            <div v-if="!detailNewMode" @click="deleteAlarmModal = true">Delete</div>
          </div>

          <div class="profileform">
            <div v-if="!detailNewMode" class="alarm_row">
              <span>Active</span><div><Toggle v-model="selectedRow.enabled" /></div>
            </div>
            <div class="alarm_row">
              <span>Name</span><div><input class="keyword_name" type="text" v-model="selectedRow.alarmname"></div>
            </div>
            <div class="alarm_row">
                <span>Location</span>
                <div>
                  <locationDrop :startingId=selectedRow.location :states=locationsArray :enabled=true @selection="LocationSelected"></locationDrop>
                </div>
            </div>
            <div class="alarm_row">
              <span>Equipment</span>
              <div>
                <equipmentDrop :startingId=selectedRow.dropletName :states=equipmentArray :enabled=true @selection="EquipmentSelected"></equipmentDrop>
              </div>
            </div>
            <div class="alarm_row">
              <span>Group</span>
              <div>
                <groupDrop :startingId=selectedRow.groupname :states=tagGroupStates :enabled=true @selection="GroupSelected"></groupDrop>
              </div>
            </div>
            <div class="alarm_row">
              <span>Tag</span>
              <div><tagDrop :startingId=selectedRow.dataname :states=tagStates :enabled=true @selection="TagSelected"></tagDrop></div>
            </div>
            <div class="alarm_row">
              <span>Operator</span>
              <div><operatorDrop :startingId=selectedRow.operatortype :states=operatorArray :enabled=true @selection="OpSelected"></operatorDrop></div>
            </div>
            <div class="alarm_row">
              <span>Value</span>
              <div><input class="keyword_name" type="text" v-model="selectedRow.alarmvalue"></div>
            </div>
            <div class="alarm_row">
            <span>Critical</span>
              <div><criticalDrop :startingId=selectedRow.critical :states=criticalArray :enabled=true @selection="CriticalSelected"></criticalDrop></div>
            </div>
            <div class="alarm_row">
              <span>Description</span>
              <div><textarea class="textarea" type="text" v-model="selectedRow.description"></textarea></div>
            </div>
            <div class="alarm_row">
              <span>Message</span>
              <div><textarea class="textarea" type="textbox" v-model="selectedRow.message"></textarea></div>
            </div>

            <div v-if="!detailNewMode" class="notification_block">
              <div class="notification_toggler">
                <div>Notifications</div>
                <span @click="EditNotification(undefined,selectedRow.id)"></span>
              </div>
              <div v-for="notif in selectedRow.notifications" class="notification_list">
                <div>{{ notif.name }}</div>
                <div>
                  <!-- <Toggle trueValue="Y" falseValue="N" v-model="notif.enabled"/> -->
                  <span style="font-weight:bold;margin-right:60px;background-image:none;font-size:18px;margin-top:-7px;color:green;padding:4px;border-radius:10px;" v-if="notif.enabled=='Y'">ENABLED</span>
                  <span style="font-weight:bold;margin-right:70px;background-image:none;font-size:18px;margin-top:-7px;color:#aaa;padding:4px;border-radius:10px;" v-if="notif.enabled=='N'">DISABLED</span>
                  <span  @click="EditNotification(notif,selectedRow.id)"></span>
                  <!-- <span style="background-image: url(../../public/img/boeing/delete.svg);" @click="DeleteNotification(notif)">D</span> -->
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="results">
          <table cellspacing="0">
            <thead>
              <tr>
                <th><span>Name</span></th>
                <th><span>Description</span></th>
                <th style="min-width: 100px;"><span>Location</span></th>
                <th style="min-width: 100px;"><span>Equip.</span></th>
                <th style="min-width: 100px;"><span>Group</span></th>
                <th><span>Tag</span></th>
                <th><span>Op.</span></th>
                <th style="min-width: 100px;"><span>Value</span></th>
                <th style="min-width: 50px;"><span>Crit.</span></th>
                <th><span>Message</span></th>
                <th style="min-width: 75px;"><span>Active</span></th>
                <th style="min-width: 90px;"><span>Edit</span></th>
              </tr>
            </thead>
            <transition-group v-if="result.length > 0">
              <tr v-for="row in result" :key="row.id" @click="highlightRow(row)"
                :class="{ 'highlighted': (row.id == highlightedRow) }">
                <td style="padding-left: 5px;">{{ row.alarmname }}</td>
                <td>{{ row.description }}</td>
                <td style="text-align: center;">{{ row.location }}</td>
                <td style="text-align: center;">{{ row.dropletName }}</td>
                <td style="text-align: center;">{{ row.groupname }}</td>
                <td style="padding-left: 5px;">{{ row.dataname }}</td>
                <td style="text-align: center;">{{ row.operatortype }}</td>
                <td style="text-align: center;">{{ row.alarmvalue }}</td>
                <td style="text-align: center;">{{ row.critical }}</td>
                <td style="padding-left: 5px;">{{ row.message }}</td>
                <td style="text-align: center;">
                  <Toggle style="margin-right: 0;" v-model="row.enabled" />
                </td>
                <td style="text-align: center;" @click="EditAlarm(row)"><img width=36 src="../../public/img/library/edit.svg"></td>
              </tr>
            </transition-group>
            <transition v-else>
              <tr>{{resultResponse}}</tr>
            </transition>
          </table>
        </div>
      </div>
    </div>
    <div class="delete_anything" v-if="deleteAlarmModal">
      <div class="buttons">
        Press Delete if you are sure you want to delete this alarm<br>
        <div class="button secondary" @click="deleteAlarmModal = false">Cancel</div>
        <div class="button" @click="DeleteAlarm(selectedRow.id)">Delete</div>
      </div>
    </div>
    <div class="delete_anything add_notification" v-if="addNotification">
      <h3 @click="here">Add / Edit Email Notification</h3>
      <div class="alarm_for"><span>Name</span><div><input class="keyword_name" v-model="currentlySelectedNotif.name" placeholder="my notification"></div></div>
      
      <div class="enabled"><span>Enabled</span><div><Toggle trueValue="Y" falseValue="N" v-model="currentlySelectedNotif.enabled"/></div></div>

      <div class="email">
        <input class="keyword_name" v-model="currentlySelectedNotif.recipient" placeholder="someone@somewhere.com">
      </div>

      <div class="buttons">
        <div class="button secondary" @click="addNotification = !addNotification;">Cancel</div>
        <div class="button" @click="SaveNotification(currentlySelectedNotif)">Save</div>
      </div>
    </div>
    <div class="context_shadow" v-if="addNotification || profile" @click="addNotification = false; profile = false;"></div>
  </div>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import Toggle from '@vueform/toggle';
import '../../src/assets/default.scss';
import locationDrop from '../components/dropdowns/dropdownbyvalue.vue';
import equipmentDrop from '../components/dropdowns/dropdown2.vue';
import groupDrop from '../components/dropdowns/dropdownbyvalue.vue';
import tagDrop from '../components/dropdowns/dropdownbyvalue.vue';
import operatorDrop from '../components/dropdowns/dropdownbyvalue.vue';
import criticalDrop from '../components/dropdowns/dropdownbyvalue.vue';

onMounted(getAlarmList);

let result = ref([]);
let deleteAlarmModal = ref(false);
let resultResponse = ref("Please wait while we load the data...");
let addNotification = ref(false);
let emails = ref(['']);
let alarms = ref(['Alarm','Alarm','Alarm','Alarm','Alarm']);
let profile = ref(false);
let highlightedRow = ref(null);
let selectedRow = ref({});
let spinner = ref(false);
let currentlySelectedLocationId = "";
let currentlySelectedEquipmentId = "";
let currentlySelectedEquipmentName = "";
let currentlySelectedGroupId = "";
let currentlySelectedTagId = "";
let currentlySelectedOpId = "";
let currentlySelectedCriticalId = "";
let currentlySelectedNotif = ref({});
let detailNewMode = ref(false);
const bdlApiPath = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/";
const getTagGroupsUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?groupSel&droplet=";
const getTagsUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?tagSel&group=";

const locationsArray = { All: "All", NGO: "NGO", TAR: "TAR", IAB: "IAB", PAE: "PAE", CHS: "CHS" };
let equipmentArray = ref({ "MTS": "MTS", "LCL": "LCL" });
const operatorArray = { 1: ">", 2: ">=",3: "==", 4: "!=", 5: "<", 6: "<=" };
const criticalArray = { 0: "No", 1: "Yes" };
let tagGroupStates = ref({});
let tagStates = ref({});
const emptyRow = ref({
    id: "",
    location: "All",
    alarmname: "",
    description: "",
    dropletName: "MTS",
    groupname: "",
    dataname: "",
    operatortype: "==",
    alarmvalue: "0.000",
    critical: "0",
    message: "",
    enabled: false
});

function EditNotification(notif,alarmid) {
  console.log("ALARMID",alarmid);
  if(notif==undefined) {
    notif = {
      "id":"-1",
      "alarmid":alarmid,
      "name":"",
      "recipient":"",
      "enabled":"N"
    }
  }
  currentlySelectedNotif.value = notif;
  addNotification.value = true;
}

function SaveNotification(notif) {
  
  let url = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/alarmnotificationsv8.php?";
  url += "alarmid="+notif.alarmid;
  url += "&name="+notif.name;
  url += "&email="+notif.recipient;
  url += "&stat="+notif.enabled;
  if(notif.id!="-1") {
    url += "&notifid="+notif.id;
  }
  console.log("NOTIFSAVEURL",url);
  fetch(url, {
      method: 'Get'
    }).then((res) => res.json()).then((response) => {
      addNotification.value = false;
      spinner.value = false;
      profile.value = false;
      getAlarmList();
    }).catch((error) => {
      console.log("error saving notification:", error);
      spinner.value = false;
    });
}

// function DeleteNotification(notif) {

// }
function DeleteAlarm(id) {
  //alert(id);
  let url = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/setAlarmv8.php?delete&id="+id;
  fetch(url, {
      method: 'Get'
    }).then((res) => res.json()).then((response) => {
      //alert(response);
      deleteAlarmModal.value = false;
      profile.value = false;
      spinner.value = false;
      getAlarmList();
    }).catch((error) => {
      console.log("error deleting alarm:", error);
      spinner.value = false;
    });
}

function SaveAlarm(row) {
    let dropletidsfornew = "";
    let equipName = "";

    let url = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/setAlarmv8.php?passThrough";
    if(row.id!=undefined) {
      url += "&update";
      url += "&id="+row.id;
      url += "&name="+encodeURI(row.alarmname);
      url += "&critical="+currentlySelectedCriticalId;
      url += "&message="+encodeURI(row.message);
      url += "&description="+encodeURI(row.description);
      url += "&oper="+currentlySelectedOpId;
      url += "&value="+row.alarmvalue;
      // if(row.critical != 0) {
      //     url += "&crit";
      // }
      if(!row.enabled) {
        url += "&enabled=N";
      } else {
        url += "&enabled=Y";
      }
    } else {
      equipName = currentlySelectedEquipmentName;
      console.log("equipName",equipName)
      if(currentlySelectedLocationId=="All") {
        if(equipName=="LCL") {
          dropletidsfornew = "110,111,210,211,310,311,410,411,500,501";
        } else {
          dropletidsfornew = "100,101,200,201,300,301,400,401,510,511";
        }
      } else {
        dropletidsfornew = EquipmentNameToId(equipName);
      }
      console.log("dropletidsfornew",dropletidsfornew)

      if(row.description==undefined) row.description ="";
      if(row.message==undefined) row.message ="";

      url += "&add";
      url += "&name="+encodeURI(row.alarmname);
      url += "&tagId="+currentlySelectedTagId;
      url += "&dropletid="+dropletidsfornew;
      url += "&critical="+currentlySelectedCriticalId;
      url += "&message="+encodeURI(row.message);
      url += "&description="+encodeURI(row.description);
      url += "&oper="+currentlySelectedOpId;
      url += "&value="+row.alarmvalue;
      // if(row.critical != 0) {
      //     url += "&crit";
      // }
      if(!row.enabled) {
        url += "&enabled=N";
      } else {
        url += "&enabled=Y";
      }
    }
    
    spinner.value = true;
    console.log("SAVEURL",url);
    fetch(url, {
      method: 'Get'
    }).then((res) => res.json()).then((response) => {
      profile.value = false;
      spinner.value = false;
      getAlarmList();
    }).catch((error) => {
      console.log("error saving alarm:", error);
      spinner.value = false;
    });
}
function EquipmentNameToId(name) {
  let id = "";
  switch(name) {
    case "LCL":
      id = "110";
      break;
    case "MTS":
      id = "100";
      break;
    case "MTS1":
      id = "100";
      break;
    case "MTS4":
      id = "101";
      break;
    case "LCL1":
      id = "110";
      break;
    case "LCL3":
      id = "111";
      break;
    case "MTS2":
      id = "200";
      break;
    case "MTS3":
      id = "201";
      break;
    case "LCL2":
      id = "210";
      break;
    case "LCL4":
      id = "211";
      break;
    case "MTS5":
      id = "300";
      break;
    case "MTS7":
      id = "301";
      break;
    case "LCL5":
      id = "310";
      break;
    case "LCL7":
      id = "311";
      break;
    case "MTS6":
      id = "400";
      break;
    case "MTS9":
      id = "401";
      break;
    case "LCL6":
      id = "410";
      break;
    case "LCL8":
      id = "411";
      break;
    case "MTS8":
      id = "510";
      break;
    case "MTS10":
      id = "511";
      break;
    case "LCL9":
      id = "500";
      break;
    case "LCL10":
      id = "501";
  }
  return id;
}
function getKeyByValue(obj, value) {
  return Object.keys(obj).filter(key => obj[key] === value);
}
function here() {
  console.log(emails.value, emails)
}
function EditAlarm(row) {
  profile.value = true;
  selectedRow.value = row;
  SetEquipmentList(row.location);
}

function SetEquipmentList(locationName) {
  console.log("locationName",locationName);
  if(locationName=="All") {
      equipmentArray.value = { "All MTS": "MTS", "All LCL": "LCL" };
  } else {
    if(locationName=="NGO") {
      equipmentArray.value = { "MTS1": "MTS1","MTS4": "MTS4", "LCL1": "LCL1", "LCL3": "LCL3" };
    }
    if(locationName=="TAR") {
      equipmentArray.value = { "MTS2": "MTS2","MTS3": "MTS3", "LCL2": "LCL2", "LCL4": "LCL4" };
    }
    if(locationName=="IAB") {
      equipmentArray.value = { "MTS5": "MTS5","MTS7": "MTS7", "LCL5": "LCL5", "LCL7": "LCL7" };
    }
    if(locationName=="PAE") {
      equipmentArray.value = { "MTS6": "MTS6","MTS9": "MTS9", "LCL6": "LCL6", "LCL8": "LCL8" };
    }
    if(locationName=="CHS") {
      equipmentArray.value = { "MTS8": "MTS8","MTS10": "MTS10", "LCL9": "LCL9", "LCL10": "LCL10" };
    }
  }
  console.log("SetEquipmentList:",equipmentArray.value)
}

function NewAlarm() {
  detailNewMode.value = true;
  profile.value = true;
  selectedRow.value = emptyRow;
  currentlySelectedLocationId = "All";
  currentlySelectedEquipmentName = "MTS";
  EquipmentSelected("MTS");
}

function highlightRow(row) {
  console.log("ROW::",row);
  detailNewMode.value = false;
  highlightedRow.value = row.id;
  currentlySelectedOpId = getKeyByValue(operatorArray, row.operatortype);
  currentlySelectedCriticalId = row.critical;
  selectedRow.value = row;
  if (row.dropletName == "All MTS") {
    EquipmentSelected("MTS", row.groupname);
  }
  else {
    EquipmentSelected("LCL", row.groupname);
  }
}

function OpSelected(id) {
  currentlySelectedOpId = id;
}

function CriticalSelected(id) {
  currentlySelectedCriticalId = id;
}

function LocationSelected(id) {
  //console.log("equip",id);
  currentlySelectedLocationId = id;
  SetEquipmentList(id);
}

function EquipmentSelected(name, groupname) {
  currentlySelectedEquipmentName = name;
  console.log("EQUIPSELECT",name);
  let id = EquipmentNameToId(name);
  console.log("EQUIPSELECT-ID",id);
  currentlySelectedEquipmentId = id;
  fetch(getTagGroupsUrl + id, {
    headers: { 'Content-type': 'application/json' },
  }).then((res) => res.json()).then((response) => {
    tagGroupStates.value = response;
    currentlySelectedGroupId = getKeyByValue(response, groupname);
    GroupSelected(currentlySelectedGroupId);
    console.log("GROUPS", tagGroupStates.value);
  }).catch((error) => {
    console.log("TAG GROUP FETCH ERROR " + error);
  });
}

function GroupSelected(id) {
  console.log("get tag list", id[0]);
  fetch(getTagsUrl + id, {
    headers: { 'Content-type': 'application/json' },
  }).then((res) => res.json()).then((response) => {
    tagStates.value = response;
  }).catch((error) => {
    console.log("TAGS FETCH ERROR " + error);
  });
}

function TagSelected(id) {
  currentlySelectedTagId = id;
}

function getAlarmList() {
  result.value = [];
  resultResponse = "Please wait while we load the data...";
  const url = bdlApiPath + "alarmSummaryv8.php";

  fetch(url, {
    method: 'Get'
  }).then((res) => res.json()).then((response) => {
   // console.log('wtf1', response);
    result.value = response;
    highlightRow(response[0]);
    spinner.value = false;
  }).catch((error) => {
    resultResponse = "Error loading series data";
    console.log("error loading series data:", error);
    spinner.value = false;
  });
}
</script>

<style lang="scss" scoped>
.notification_block{
  padding: 10px;
  background-color: #7878780D;
  border-radius: 12px;
  >div{
    display: flex;
    flex-direction: row;
  }
  .notification_toggler{
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    div{
      position: relative;
    }
    div::after{
      content: '';
      height: 24px;
      width: 24px;
      position: absolute;
      right: -30px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url(../../public/img/boeing/speaker.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
    span{
      display: inline-block;
      height: 24px;
      aspect-ratio: 1/1;
      border-radius: 25%;
      background-color: #252525;
      color: #fff;
      background-image: url(../../public/img/boeing/ma/plus.svg);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      justify-content: center;
      margin-left: auto;
    }
  }
  .notification_list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #444;
    height: 40px;
    >div{
      height: 40px;
      display: flex;
      justify-items: center;
      align-items: center;
      span{
        display: inline-block;
        height: 30px;
        width: 30px;
        cursor: pointer;
        background-image: url(../../public/img/library/edit.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
.add_notification{
  max-width: 500px;
  h3{
    font-weight: 500;
  }
  .enabled,.alarm_for{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    >span{
      width: 150px;
    }
  }
  .email{
    display: flex;
    flex-direction: column;
    color: #787878;
    flex-wrap: wrap;
    margin-top: 20px;
    >div{
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      >span{
        width: 96px;
        color: #787878;
        align-self: center;
      }
      input{
        flex-grow: 1;
        height: 40px;
        outline: none;
        border: 1px solid #bcbcbc;
        border-radius: 8px;
        padding-left: 10px;
        font-size: 1rem;
      }
    }
    .add_email{
      padding-left: 126px;
      font-size: .75rem;
      background-image: url(../../public/img/section/add_line.svg);
      background-repeat: no-repeat;
      background-position: center left 96px;
      cursor: pointer;
    }
  }
  .grey_mid{
    background-color: #7878780D;
    padding: 10px;
    border-radius: 8px;
    >div{
      display: flex;
      flex-wrap: wrap;
      >span{
        max-width: 150px;
        margin-right: 10px;
        align-self: center;
      }
      textarea{
        border: 1px solid #bcbcbc;
        border-radius: 8px;
        resize: none;
        height: 80px;
        flex-grow: 1;
        box-sizing: border-box;
        outline: none;
        font-size: 1rem;
        font-family: "Inter", sans-serif;
        padding: 5px 0 0 10px;
      }
    }
    p{
      margin: 10px 0 0;
    }
  }
  .buttons{
    .button{
      width: calc(50% - 8px);
    }
  }
  input::placeholder,textarea::placeholder{
    color: #bcbcbc;
  }
}

.keyword_name {
  flex-grow: 1;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 1rem;
  padding: 4px;
  color: #444;
  border-color: #444;
  border-radius: 10px;
  border-width: 1px;
}

.highlighted {
  background-color: #ddd;
}

.main_v8 {
  padding: 0;
  background-color: #fff;
}

.toggle {
  width: 57px !important;
}
.results{
  table{
    border-collapse: separate;
    thead{
      th{
        height: 44px;
        padding: 0px;
        border-right: 1px solid #bcbcbc;
        background-color: #fff;
        span{
          font-weight: 400;
          font-family: 'Inter', sans-serif;
        }
      }
    }
    td{
      padding: 10px 0;
    }
    td:not(:last-child){
      border-right: 1px solid #bcbcbc;
    }
    tr:nth-child(even){
      background-color: #7878780D;
    }
    tr:nth-child(odd){
      background-color: #f2f2f2;
    }
  }
}

.alarmarea {
  background-color: #fff;
  .alarmarea_header {
    height: 70px;
    background-color: #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    h3{
      height: 70px;
      line-height: 70px;
      margin: 0;
    }
    span{
      margin-left: auto;
      margin-right: 20px;
      background-image: url(../../public/img/boeing/plus.svg);
      height: 22px;
      width: 22px;
      align-self: center;
    }
  }
}

.profiletile {
  width: 500px;
  overflow: auto;
  background-color: #fff;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 3;

  p {
    padding-left: 20px;
    margin-bottom: 0px;
  }

  .profiletile_top {
    height: 75px;
    padding-left: 20px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h3 {
      margin: 0px;
      height: 75px;
      line-height: 75px;
    }

    span {
      display: inline-block;
      height: 75px;
      width: 75px;
      margin-left: auto;
      background-image: url(../../public/img/boeing/close.svg);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
  }

  .profileform {
    padding: 20px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    line-height: 36px;
    .alarm_row{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      >span{
        width: 140px;
      }
      >div{
        height: 40px;
        flex: 1;
        min-height: 40px;
        textarea,input{
          font-family: 'Inter', sans-serif;
          width: 100%;
          box-sizing: border-box;
          border: 1px solid #BCBCBC;
          resize: none;
          border-radius: 10px;
          font-size: 1rem;
          outline: none;
          padding-left: 10px;
          color: #444;
          height: 40px;
        }
      }
    }
  }

  .profilebuttons {
    padding: 20px;
    border-radius: 16px;
    display: flex;
    justify-content: center;

    >div {
      color: #252525;
      width: 80px;
      height: 80px;
      line-height: 36px;
      font-size: 1rem;
      // padding-top: 56px;
      text-align: center;
      color: #787878;
      display: flex;
      flex-direction: row;
      align-items: end;
      justify-content: center;
      background-repeat: no-repeat;
      background-position: top center;
      // -webkit-touch-callout: none; /* iOS Safari */
      // -webkit-user-select: none; /* Safari */
      // -khtml-user-select: none; /* Konqueror HTML */
      // -moz-user-select: none; /* Old versions of Firefox */
      // -ms-user-select: none; /* Internet Explorer/Edge */
      // user-select: none;
      cursor: pointer;
    }

    >div:nth-child(1) {
      background-image: url(../../public/img/boeing/datatrending/new.svg);
    }

    >div:nth-child(2) {
      background-image: url(../../public/img/boeing/datatrending/save.svg);
    }

    >div:nth-child(3) {
      background-image: url(../../public/img/boeing/datatrending/save_as.svg);
    }

    >div:nth-child(4) {
      background-image: url(../../public/img/boeing/datatrending/delete.svg);
    }
  }
}
.context_shadow {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
}
.plainspan {
  margin-right:0px;
}
</style>
